<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-adicionar to="/situacoes-ocorrencia/adicionar" />
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="situacoesOcorrencia"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="carregarSituacoesOcorrencia"
          >
            <template
              v-slot:[`item.actions`]="{ item }"
              class="text-right"
            >
              <table-btn-editar :href="`/situacoes-ocorrencia/${item.id}/editar`" />
              <table-btn-deletar @click="deletarSituacaoOcorrencia(item)" />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <dialog-deletar
      :mostrar="dialogDeletar"
      :carregando="carregandoDeletar"
      @confirmar="deletar"
      @fechar="dialogDeletar = false"
    />
  </v-container>
</template>

<script>
  import situacoesOcorrencia from '@/api/situacoes-ocorrencia'

  export default {

    components: {
      DialogDeletar: () => import('@/components/dialog/DialogDeletar'),
    },

    data () {
      return {
        carregando: false,
        carregandoDeletar: false,
        dialogDeletar: false,
        totalRegistros: 0,
        idExcluir: null,
        cabecalho: [
          { text: '#', value: 'id' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Ações', value: 'actions', align: 'right' },
        ],
        situacoesOcorrencia: [],
        paginacao: {},
      }
    },

    methods: {
      async carregarSituacoesOcorrencia () {
        try {
          this.carregando = true

          const resposta = await situacoesOcorrencia.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
          })

          this.situacoesOcorrencia = resposta.data.situacoes_ocorrencia
          this.totalRegistros = resposta.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

      async deletar () {
        try {
          this.carregandoDeletar = true

          await situacoesOcorrencia.deletar(this.idExcluir)

          this.carregarSituacoesOcorrencia()

          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_deletar') })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoDeletar = false
          this.dialogDeletar = false
        }
      },

      deletarSituacaoOcorrencia (situacao) {
        this.idExcluir = situacao.id
        this.dialogDeletar = true
      },

    },

  }
</script>
